export function dollars(val) {
  return `$${val.toFixed(2)}`
}

export function filingMethodTimingNoticeText(filingMethod) {
  let noticeText = 'Filed in'

  let { days, hours } = filingMethod.filed_in

  if (days) {
    noticeText += ` ${days} day${days > 1 ? 's' : ''}`
  }

  if (hours) {
    noticeText += ` ${days ? ', ' : ''} ${hours} hour${hours > 1 ? 's' : ''}`
  }

  if (!days && !hours) {
    noticeText += 'stantly'
  }

  days = filingMethod.docs_in.days
  hours = filingMethod.docs_in.hours

  noticeText += ', documents received after'

  if (days) {
    noticeText += ` ${days} more day${days > 1 ? 's' : ''}`
  }

  if (hours) {
    noticeText += ` and ${hours} hour${hours > 1 ? 's' : ''}`
  }

  if (!days && !hours) {
    noticeText += 'wards'
  }

  return noticeText
}
