<template>
  <b-card>
    <b-card-text>
      <h3 class="mt-0">
        {{ product.name }} &mdash; {{ product.price | dollars }}
      </h3>
    </b-card-text>

    <ct-centered-spinner v-if="loading" />

    <b-button
      v-else-if="cartProduct"
      variant="danger"
      class="float-right mt-1"
      aria-label="remove button"
      @click="remove"
    >
      Remove
    </b-button>

    <b-button
      v-else-if="eligibleToOrderLegalDocs"
      variant="primary"
      class="float-right mt-1"
      aria-label="add to cart button"
      @click="completed"
    >
      Add to Cart
    </b-button>

    <div v-else>
      Product Already Purchased.

      <span v-if="legalDocNames.includes(product.name)">
        Navigate to Stageline to fill out or download your pdf.
      </span>
    </div>
  </b-card>
</template>

<script>
import { dollars } from '@/filters'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SupplyConsultationCheckout',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
  },
  filters: {
    dollars,
  },
  props: {
    product: Object,
    company: Object,
  },
  data() {
    return {
      legalDocNames: ['Premium Operating Agreement', 'Premium Corporate Bylaws'],
      loading: false,
    }
  },
  computed: {
    ...mapGetters('companies', ['activeOrderItemsByProductName']),
    ...mapGetters('checkout', ['productByTitleInCart']),
    cartProduct() {
      return this.productByTitleInCart(this.product.name)
    },
    eligibleToOrderLegalDocs() {
      return !this.legalDocNames.some(name => this.activeOrderItemsByProductName(name) && name === this.product.name)
    },
  },
  methods: {
    ...mapActions('checkout', [
      'addToCart',
      'removeFromCart',
    ]),
    async completed() {
      this.loading = true
      await this.addToCart({ ...this.product })
      this.loading = false
      this.$emit('completed')
    },
    async remove() {
      this.loading = true
      await this.removeFromCart({ id: this.cartProduct.id })
      this.loading = false
    },
  },
}
</script>
