var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    [
      _c("b-card-text", [
        _c("h3", { staticClass: "mt-0" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.product.name) +
              " — " +
              _vm._s(_vm._f("dollars")(_vm.product.price)) +
              "\n    "
          ),
        ]),
      ]),
      _vm.loading
        ? _c("ct-centered-spinner")
        : _vm.cartProduct
        ? _c(
            "b-button",
            {
              staticClass: "float-right mt-1",
              attrs: { variant: "danger", "aria-label": "remove button" },
              on: { click: _vm.remove },
            },
            [_vm._v("\n    Remove\n  ")]
          )
        : _vm.eligibleToOrderLegalDocs
        ? _c(
            "b-button",
            {
              staticClass: "float-right mt-1",
              attrs: { variant: "primary", "aria-label": "add to cart button" },
              on: { click: _vm.completed },
            },
            [_vm._v("\n    Add to Cart\n  ")]
          )
        : _c("div", [
            _vm._v("\n    Product Already Purchased.\n\n    "),
            _vm.legalDocNames.includes(_vm.product.name)
              ? _c("span", [
                  _vm._v(
                    "\n      Navigate to Stageline to fill out or download your pdf.\n    "
                  ),
                ])
              : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }